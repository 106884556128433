<template>
  <div class="m-page">
    <img src="https://img.miaocang.cc/pcImg/cloud/exhibition_transportation.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: 'CloudExhibitionTransportation',

  created() {
    document.title = '交通指南'
  }
}
</script>

<style lang="less" scoped>
.m-page {
  min-height: 100vh;
  text-align: center;
  background-color: #fff;
  img {
    width: 100vw;
    min-width: 320px;
    max-width: 640px;
  }
}
</style>